// import { DUMMY_SECONDS } from '@/utils/dummy-data'

export const namespaced = true

export const state = {
  saveState: false,
  universities: [],
  productCategory: [],
  coachingCourse: [],
  promoHighlight: [],
  categories: [],
  post: [],
  faq: [],
  headerConfig: {
    type: '',
    title: '',
  },
  mixinsModalOtp: {
    isOpenModalEmailOtp: false,
    email: '',
    otp: '',
    isValidOtp: null,
    countDown: 30, // DUMMY_SECONDS,
    countDownStatus: 'idle',
    interval: null,
  },
  mixinsLogin: {
    isLoadingLoginWithGoogle: false,
    isLoadingLoginWithEmail: false,
  },
  paymentPage: {
    isRouteGuardActive: false,
    isOpenModalConfirmExitTransactionPage: false,
    customChangeFn: 'asdasd', // LOGOUT
  },
}

export const getters = {
  saveState: (state) => state.saveState,
  universities: (state) => state.universities,
  productCategory: (state) => state.productCategory,
  coachingCourse: state => state.coachingCourse,
  promoHighlight: state => state.promoHighlight,
  categories: state => state.categories,
  post: state => state.post,
  faq: state => state.faq,
  headerConfig: state => state.headerConfig,
  mixinsModalOtp: state => state.mixinsModalOtp,
  mixinsLogin: state => state.mixinsLogin,
  paymentPage: state => state.paymentPage,
}

export const mutations = {
  setSaveState(state, saveState) {
    state.saveState = saveState
  },
  setUniversities(state, universities) {
    state.universities = universities
  },
  setProductCategory(state, data) {
    state.productCategory = data
  },
  setCoachingCourse(state, data) {
    state.coachingCourse = data
  },
  setPromoHighlight(state, data) {
    state.promoHighlight = data
  },
  setCategories(state, data) {
    state.categories = data
  },
  setPost(state, data) {
    state.post = data
  },
  setFaq(state, data) {
    state.faq = data
  },
  setHeaderConfig(state, data) {
    state.headerConfig = data
  },
  setMixinsModalOtp(state, { key, data }) {
    state.mixinsModalOtp[key] = data
  },
  setMixinsLogin(state, { key, data }) {
    state.mixinsLogin[key] = data
  },
  setPaymentPage(state, arr) {
    for (const key in arr) {
      state.paymentPage[key] = arr[key]
    }
  },
}

export const actions = {
  loadUniversities(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/general/universities')
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setUniversities', it)
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  createUniversities(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/general/universities', data)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  loadProductCategory(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/general/product-category')
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setProductCategory', it)
      })
      .catch((err) => {
        throw err.response
      })
  },
  getCoachingCourse(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/general/coaching')
      .then((it) => it.data)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setCoachingCourse', it)
      })
  },
  getPromoHighlight(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/general/promo-highlight')
      .then((it) => it.data)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setPromoHighlight', it)
      })
  },
  getPromoDetail(ctx, promoId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/general/promo/${promoId}`)
      .then((it) => it.data)
  },
  getCategories(ctx) {
    let axios = ctx.rootGetters.axiosWordpress
    return axios
      .get('/categories?per_page=3&hide_empty=true&orderby=count&order=desc')
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setCategories', it)
      })
  },
  getPost(ctx, categories) {
    let axios = ctx.rootGetters.axiosWordpress
    return axios
      .get(`/posts?per_page=3&orderby=date&order=desc&categories=${categories}`)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setPost', it)
      })
  },
  getFaq(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/general/faq')
      .then((it) => it.data)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setFaq', it)
      }) 
  },
  /**
   * @param {string} productServiceId
   *
   * @returns {Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *         id: string,
   *         productId: string,
   *         programsService: string,
   *         duration: number,
   *         price: number,
   *         serviceTarget: string,
   *         isRecommended: number,
   *         description: string,
   *         order: number,
   *         product: {
   *             photoUrl: string
   *         }
   *     },
   *     meta: {}
   * }>}
   */
  getProductServiceId(ctx, productServiceId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/general/product-service/${productServiceId}`)
      .then((it) => {
        return it.data
      })
      .catch((err) => {
        throw err.response
      })
  },

  /**
   * @param {string} productId
   *
   * @returns {Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *         id: string,
   *         name: string,
   *         photoUrl: string,
   *         description: string,
   *         otherProblems: {
   *            id: string,
   *            productId: string,
   *            title: string,
   *             image: string,
   *             description: string,
   *             order: number,
   *         }[]
   *     },
   *     meta: {}
   * }>}
   */
  getProgramPageById(ctx, productId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/general/program-page/${productId}`)
      .then((it) => {
        return it.data
      })
      .catch((err) => {
        throw err.response
      })
  },

  /**
   * @returns {Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *         id: string,
   *         name: string,
   *         overview: string,
   *         photoUrl: string,
   *     }[],
   *     meta: {}
   * }>}
   */
  getProgramPageAllProduct(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/general/program-page/all-product?temporary=yes')
      .then((it) => {
        return it.data
      })
      .catch((err) => {
        throw err.response
      })
  },
 
  /**
   * @returns {Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *       id: string,
   *       name: string,
   *       age: number,
   *       location: string,
   *       image: string,
   *       description: string,
   *       order: number,
   *       product: {
   *          id: string,
   *          name: string,
   *       },
   *     }[],
   *     meta: {}
   * }>}
   */
  getTestimoni(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/general/testimoni')
      .then((it) => {
        return it.data
      })
      .catch((err) => {
        throw err.response
      })
  },

  /**
    * @param {number} duration
    * 
    * @returns {Promise<{
    *     status: boolean,
    *     message: string,
    *     data: {
    *         topTitle: string,
    *         product: {
    *           id: string,
    *           facility: string,
    *           simple: string,
    *           intensive: string,
    *           order: number,
    *         }[],
    *         accordion: {
    *           id: string,
    *           title: string,
    *           body: string,
    *         }[],
    *     },
    *     meta: {}
    * }>}
  */
  getProductComparation(ctx, duration) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/general/product-comparation/${duration}`)
      .then((it) => {
        return it.data
      })
      .catch((err) => {
        throw err.response
      })
  },

  /**
    * @returns {Promise<{
    *     status: boolean,
    *     message: string,
    *     data: {
    *         facility: {
    *           facility: string,
    *         }[],
    *         result: {
    *           id: string,
    *           programService: string,
    *           price: number,
    *           productFacilitiesComparation: {
    *             value: string,
    *             productFacilities: {
    *               facility: string,
    *             }
    *           }[],
    *         }[],
    *     },
    *     meta: {}
    * }>}
  */
  getProgramConsultation(ctx) {
    let axios = ctx.rootGetters.axios
    return axios  
      .get('/v1/general/program-konsultasi/comparation')
      .then((it) => {
        return it.data
      })
      .catch((err) => {
        throw err.response
      })
  },
}