import Vue from 'vue'
import Chakra, { CThemeProvider, CReset } from '@chakra-ui/vue'
import VueRouter from 'vue-router'
import VTooltipPlugin from 'v-tooltip'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar'
import PortalVue from 'portal-vue'
import './assets/globals.css'
import App from './App.vue'
import { routes } from './routes'
import customTheme from './styles/custom-theme.js'
import { store } from '@/stores'
import _ from 'lodash'
import Vuelidate from 'vuelidate'
import VueCompositionApi, { createApp, defineComponent } from '@vue/composition-api'
import VueYouTubeEmbed from 'vue-youtube-embed'
import { formatDate } from '@/utils/format-date'
import VueMeta from 'vue-meta'
import wysiwyg from 'vue-wysiwyg'
import 'vue-wysiwyg/dist/vueWysiwyg.css'
import { removeNull } from '@/utils/filters'
import './registerServiceWorker'
import VueFormulate from '@braid/vue-formulate'
// import OneSignalVue from 'onesignal-vue'
import InputVueFormulate from '@/components/elements/input-vue-formulate.vue'
import InputDateVueFormulate from '@/components/elements/input-date-vue-formulate.vue'
import CustomLabel from '@/components/elements/custom-label.vue'
import SelectVueFormulate from '@/components/elements/select-vue-formulate.vue'
import PasswordVueFormulate from '@/components/elements/password-vue-formulate.vue'
import VueSkeletonLoader from 'skeleton-loader-vue'
import { VueQueryPlugin } from '@tanstack/vue-query'
import InlineSvg from 'vue-inline-svg'
import VueMobileDetection from 'vue-mobile-detection'
import VueMask from 'v-mask'
import VueScrollTo from 'vue-scrollto'
import posthog from 'posthog-js'
import posthogPlugin from '@/plugins/posthog'
import VueTour from 'vue-tour'

window.__ = _

Vue.component('InlineSvg', InlineSvg)
Vue.component('CustomLabel', CustomLabel)
Vue.component('InputVueFormulate', InputVueFormulate)
Vue.component('InputDateVueFormulate', InputDateVueFormulate)
Vue.component('SelectVueFormulate', SelectVueFormulate)
Vue.component('PasswordVueFormulate', PasswordVueFormulate)
Vue.component('VueSkeletonLoader', VueSkeletonLoader)
Vue.component('VCalendar', Calendar)
Vue.component('VDatePicker', DatePicker)

require('vue-tour/dist/vue-tour.css')

// Use plugin defaults (optional)
Vue.use(setupCalendar, {})
Vue.use(VueTour)
Vue.use(posthogPlugin)
Vue.use(VueMask)
Vue.use(VueQueryPlugin)
Vue.use(VueCompositionApi)
Vue.use(VTooltipPlugin)
Vue.use(PortalVue)
Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(VueYouTubeEmbed)
Vue.use(VueMeta)
Vue.use(VueMobileDetection)
Vue.use(VueScrollTo)
Vue.use(wysiwyg, { hideModules: { image: true } })
Vue.use(Chakra, { extendTheme: customTheme })
Vue.use(VueFormulate, {
  library: {
    password: {
      component: 'PasswordVueFormulate',
    },
    select: {
      component: 'SelectVueFormulate',
    },
    tel: {
      component: 'InputVueFormulate',
    },
    text: {
      component: 'InputVueFormulate',
    },
    date: {
      component: 'InputDateVueFormulate',
    },
  },
  slotComponents: {
    label: 'CustomLabel',
  },
  rules: {
    hasAlphaNum: ({ value }) => {
      return /[a-zA-Z0-9]+/.test(value) && value?.length > 0
    },
    hasSpecialCharacter: ({ value }) => {
      return /[@$!%*?&()#]/.test(value) && value?.length > 0
    },
    hasUpperCase: ({ value }) => {
      return /[A-Z]/.test(value) && value?.length > 0
    },
    hasLowerCase: ({ value }) => {
      return /[a-z]/.test(value) && value?.length > 0
    },
  },
  locales: {
    en: {
      hasAlphaNum() {
        return 'alphanumeric'
      },
      hasSpecialCharacter() {
        return 'must include unique @$!%()*?&'
      },
      hasUpperCase() {
        return 'uppercase letter'
      },
      hasLowerCase() {
        return 'lowercase letters'
      },
    },
  },
})
// if (process.env.NODE_ENV === 'production') {
//   Vue.use(OneSignalVue)
// }

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function(to) {
    if (to.params.savePosition) return {}
    if (to.hash) {
      return { selector: to.hash || to, behavior: 'smooth' }
      //Or for Vue 3:
      //return {el: to.hash}
    } else {
      return window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  },
  routes,
})

router.afterEach((to) => {
  const userType = router.app.$store.getters['userType/userType']
  posthog?.capture('$pageview', {
    $current_url: to.fullPath,
    userType,
  })
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !router.app.$store.getters['auth/isAuthenticated']) {
    return next({
      path: '/auth',
      query: { redirect: to.fullPath },
    })
  }
  if (to.meta.isNutritionist && router.app.$store.state.auth?.user?.role !== 'nutritionist') {
    return next({
      path: '/',
    })
  } else if (to.meta.isAdmin && router.app.$store.state.auth?.user?.role !== 'admin' && router.app.$store.state.auth?.user?.role !== 'super_admin') {
    return next({
      path: '/',
    })
  }
  if (router.app.$store.getters['auth/isAuthenticated'] && to.name.includes('auth')) {
    return next({
      path: '/',
    })
  }
  next()
})

Vue.filter('formatDate', formatDate)
Vue.filter('satuanMakanan', (value) => {
  switch (value) {
    case 'sendok_makan':
      return 'sdm'
    case 'porsi':
    default:
      return value
  }
})
Vue.filter('toFixed', (value, digits = 2) => {
  return parseFloat(value).toFixed(digits)
})
Vue.filter('formatNull', (value) => value ?? '-')
Vue.filter('removeNull', removeNull)
Vue.filter('parseFloat', (it) => parseFloat(it))
Vue.mixin({
  computed: {
    axios() {
      return this.$store.getters.axios
    },
    isDevelopment() {
      return process.env.NODE_ENV === 'development'
    },
    programId: {
      get() {
        return this.$route.params.programId ?? this.$route.query.programId
      },
      set(v) {
        this.$router.replace({
          ...this.$router,
          query: { programId: v },
        })
      },
    },
    clientId() {
      if (this.$route.params.clientId != null) {
        return this.$route.params.clientId
      } else {
        let isClient = store.state.auth.user?.role === 'client'
        let userId = store.state.auth.user?.id
        return isClient ? userId : null
      }
    },
    authRole() {
      return this.$store.state.auth?.user?.role
    },
    isRoleSuperAdmin() {
      return this.$store.state.auth?.user?.role === 'super_admin'
    },
    isRoleAdmin() {
      return this.$store.state.auth?.user?.role === 'admin'
    },
    isClient() {
      return this.$store.state.auth?.user?.role === 'client'
    },
    isRoleNutritionist() {
      return this.$store.state.auth?.user?.role === 'nutritionist'
    },
  },
  methods: {
    $successToast({ message, title = 'Success' }) {
      this.$toast({
        title: title,
        description: message,
        status: 'success',
        variant: 'subtle',
        position: 'bottom-right',
      })
    },
    $errorToast({ message, title = 'Failed' } = {}) {
      this.$toast({
        title: title,
        description: message,
        status: 'error',
        variant: 'subtle',
        position: 'bottom-right',
      })
    },
    $errorToastFromCatch(error) {
      let message = error.response?.data?.message ?? error.message ?? error.toString()
      this.$errorToast({
        message,
      })
    },
    $logJson(...data) {
      try {
        data = data.map((it) => JSON.parse(JSON.stringify(it)))
        console.log(...data)
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(data)))
      }
    },
  },
})

let app = createApp(
  defineComponent({
    store,
    router,
    provide: {
      store,
      router,
      axios: store.getters.axios,
      $router: router,
      $store: store,
      $logJson(...data) {
        try {
          data = data.map((it) => JSON.parse(JSON.stringify(it)))
          console.log(...data)
        } catch (e) {
          console.log(JSON.parse(JSON.stringify(data)))
        }
      },
    },

    // Comment Code for One Signal
    // async beforeMount() {
    //   if (process.env.NODE_ENV === 'production') {
    //     window.$OneSignal = this.$OneSignal

    //     await this.$OneSignal.init({
    //       appId: 'e9a022ac-5240-4464-a60c-be0dfd45f344',
    //       allowLocalhostAsSecureOrigin: true,
    //       serviceWorkerPath: 'service-worker.js',
    //       autoRegister: true,
    //     })
    //     await this.$OneSignal.registerForPushNotifications({
    //       httpPermissionRequest: true,
    //       slidedown: true,
    //     })

    //     await this.$OneSignal.setExternalUserId('test-user-external')

    //     let userId = await this.$OneSignal.getUserId()
    //     console.log('userId', userId)

    //     let isPNEnabled = await this.$OneSignal.isPushNotificationsEnabled()
    //     console.log('isPNEnabled', isPNEnabled)

    //     let permission = await this.$OneSignal.getNotificationPermission()
    //     console.log('permission', permission)
    //   }
    // },
    render(h) {
      return h(CThemeProvider, [h(CReset), h(App)])
    },
  }),
)

app.mount('#app')

// For Google Login Stuff
window.onload = function() {
  let search = new URLSearchParams(location.search)
  // if this window are opened using window.open
  if (window.opener && search.has('code')) {
    window.opener.postMessage({ code: search.get('code') }, window.location.origin)
  }
}
